// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Inter/Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Inter/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___})
    format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___})
    format("truetype");
  font-weight: normal;
}

body {
  font-family: "HelveticaNeueCyr", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
  /* background-color: #09363F; */
  /* z-index: -100; */
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAEA;EACE,oBAAoB;EACpB,+DAA+D;EAC/D,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,+DAA6D;EAC7D,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B;sBACoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B;sBACoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,yBAAiB;UAAjB,iBAAiB;EACjB,+BAA+B;EAC/B,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE;aACW;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap\");\n\n@font-face {\n  font-family: \"Inter\";\n  src: url(\"./fonts/Inter/Inter-SemiBold.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  src: url(\"./fonts/Inter/Inter-Medium.ttf\") format(\"truetype\");\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: \"HelveticaNeueCyr\";\n  src: url(\"./fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.otf\")\n    format(\"truetype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"HelveticaNeueCyr\";\n  src: url(\"./fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.otf\")\n    format(\"truetype\");\n  font-weight: normal;\n}\n\nbody {\n  font-family: \"HelveticaNeueCyr\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 16px;\n}\n\n* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  user-select: none;\n  /* background-color: #09363F; */\n  /* z-index: -100; */\n  /* overflow: hidden; */\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
